var generic = generic || {};
var site = site || {};

// resource bundling

site.drupalForm = {
  initFbF : function() {
    $("div.form-builder-container").each(function(i, obj) {
      var fbfContainerNode = $(obj);
    var fbfFormNode = fbfContainerNode.find("form");
    var fbfSubmitNode = fbfContainerNode.find("input[type='submit']");
    var fbfProgressNode = fbfContainerNode.find("span.progress");
    var fbfSuccessNode = fbfContainerNode.find("span.success");
    var fbfInputNode = fbfContainerNode.find("input.email-signup");
    // NOTE. email opt status node isn't part of contact-us so for now
    // just leaving it the way it is
    var emailOptStatusNode = $("#address-info-newsletter");
    //fbfFormNode.bind("submit", function(submitEvt) {
    var form_id = fbfFormNode.find('input[name=form_id]').val();
    fbfFormNode.submit(function(submitEvt) {
      submitEvt.preventDefault();
      fbfProgressNode.removeClass("hidden");
      errorMsg = '';
      showErrors = false
      pass_required = false;
      // retrieve form data in querystring format
      var formSerial = $(this).serializeArray();
      $.each(formSerial, function(i, val) {
        val.value = $.trim(val.value).replace(/\r\n|\r|\n/g, '<br>').replace(/[\\"']/g, '\\$&').replace(/\u0000/g, '\\0');
      });
      formSerial = $.param(formSerial).replace(/\+/g, ' ');
      // transform string into array of form elements
      var paramArr = formSerial.split("&"),
      paramStr = "";
      origStr = "";
      // iterate through collection to transform form name/value into key/value properties of a literal object string.
      $.each(paramArr, function(index){
        // strip form builder additionals
        initial = this.split('=')[0].replace('form%5B', '');
        initial = initial.replace('%5D', '');
        initial = decodeURI(initial);
        initial = initial.replace(/\[.*\]/, '');
        paramStr += '"'+ initial + '":"' + decodeURIComponent(this.split('=')[1]) + '",';
        origStr += '"'+ this.split('=')[0] + '":"' + this.split('=')[1] + '",';

      });
      // parse the string and create the literal object
      var params = eval("(" + "{"+paramStr.substring(0,(paramStr.length-1)).replace(/undefined/gi,"").replace(/%40/gi,"@")+"}" + ")");
      var origParams = eval("(" + "{"+origStr.substring(0,(origStr.length-1)).replace(/undefined/gi,"").replace(/%40/gi,"@")+"}" + ")");
      if (params['_SUBMIT'] == 'contact_us') {
        params['suppress_conf_email'] = 1;
      }

      var iframe_url =  Drupal.settings.basePath + 'elc_form_thankyou/' + Drupal.settings.elc_form[form_id].nid + '/' + Drupal.settings.elc_form[form_id].vid;

      // validation time
      var version = parseFloat($.fn.jquery);
      $(this).find("input, select, textarea").each(function() {
        if (version < 1.6){
          classes = jQuery(this).attr('class').split(' ');
        }
        else {
          classes = jQuery(this).prop('class').split(' ');
        }
        var is_checkbox = false;
        form_field = this;
        var field_value = jQuery(form_field).attr('value').replace(/^\s+|\s+$/g, '');
        jQuery(form_field).removeClass('label-error');
        jQuery(form_field).removeClass('error');
        jQuery.each(classes.reverse(), function(key, value) {
          switch (value) {
            case 'required':
              // checkbox check
              if (jQuery(form_field).attr('type') == 'checkbox') {
                is_checkbox = true;
              }
              var is_checked;
              if (version < 1.6){
                is_checked = jQuery(form_field).attr('checked');
              }
              else {
                is_checked = jQuery(form_field).prop('checked');
              }
              // check for checkbox type
              if (form_builder_required(field_value) == false || (is_checkbox == true && is_checked == false) || (jQuery(form_field).attr('name') == 'form[COUNTRY]' && form_builder_check_letters(field_value) == false)) {
                showErrors = true;
                // fetch our validation message
                field = jQuery(form_field).attr('name').replace('form[', '');
                field = field.replace(']', '');
                if (is_checkbox == true) {
                  field = field.replace(/\[.*\]/, '');
                }
                if (typeof Drupal.settings.elc_form[form_id].validation_messages[field]['required'] != 'undefined') {
                  errorMsg += Drupal.settings.elc_form[form_id].validation_messages[field]['required'] + '<br>';
                }
                //set the error class for css
                pass_required = false;
                jQuery(form_field).prev().addClass('label-error');
                jQuery(form_field).addClass('error');
                jQuery(form_field).parents('.resizable-textarea').prev().addClass('label-error');
              }
              else {
                jQuery(form_field).prev().removeClass('label-error');
                jQuery(form_field).removeClass('error');
                jQuery(form_field).parents('.resizable-textarea').prev().removeClass('label-error');
                pass_required = true;
              }
            break;
            case 'email_format':
              if (pass_required == true) {
                if (form_builder_email_format(field_value) == false) {
                  showErrors = true;
                  // fetch our validation message
                  field = jQuery(form_field).attr('name').replace('form[', '', fbfContainerNode);
                  field = field.replace(']', '');
                  if (typeof Drupal.settings.elc_form[form_id].validation_messages[field]['email_format'] != 'undefined') {
                    errorMsg += Drupal.settings.elc_form[form_id].validation_messages[field]['email_format'] + '<br>';
                  }
                  //set the error class for css
                  jQuery(form_field).prev().addClass('label-error');
                  jQuery(form_field).addClass('error');
                }
              }
            break;
            case 'email_confirm':
              if (pass_required == true) {
                var related_field = jQuery('input[parent=' + jQuery(form_field).attr('related') + ']', fbfContainerNode);
                if (form_builder_confirm_email(field_value, jQuery(related_field).attr('value').replace(/^\s+|\s+$/g, '')) == false) {
                  showErrors = true;
                  // fetch our validation message
                  field = jQuery(form_field).attr('name').replace('form[', '');
                  field = field.replace(']', '');
                  errorMsg += Drupal.settings.elc_form[form_id].validation_messages[field]['email_confirm'] + '<br>';
                  //set the error class for css
                  jQuery(form_field).prev().addClass('label-error');
                  jQuery(form_field).addClass('error');
                }
              }
            break;
            case 'number_check':
              if (pass_required == true) {
                if (form_builder_number_format(field_value) == false) {
                  showErrors = true;
                  // fetch our validation message
                  field = jQuery(form_field).attr('name').replace('form[', '');
                  field = field.replace(']', '');
                  errorMsg += Drupal.settings.elc_form[form_id].validation_messages[field]['required'] + '<br>';
                  //set the error class for css
                  jQuery(form_field).prev().addClass('label-error');
                  jQuery(form_field).addClass('error');
                }
              }
            break;
          }
        });
      });
      // check for age check
      if (typeof Drupal.settings.elc_form[form_id].age_value != 'undefined') {
        if (form_builder_age_check(params, Drupal.settings.elc_form[form_id].age_value) == false) {
          showErrors = true;
          errorMsg += Drupal.settings.elc_form[form_id].validation_messages['AGE']['required'] + '<br>';
          jQuery('#edit-form-age-year, #edit-form-age-month, #edit-form-age-day', fbfContainerNode).addClass('error');

        }
      }

      var form_error_class = '.form-error-' + form_id;

      if (showErrors == true) {
        jQuery(form_error_class).html(errorMsg);
        jQuery(form_error_class).show();

      }
      else {
        jQuery(form_error_class).hide();
        fbfSubmitNode.addClass("hidden");

        // Pass through form
        if (params._SUBMIT == 'pass-thru') {
          if (typeof Drupal.settings.elc_form[form_id].success_page != 'undefined') {
            var send_url = Drupal.settings.elc_form[form_id].success_page + "?";

            if (Drupal.settings.elc_form[form_id].use_iframe == true){
              send_url = iframe_url;
            }

            jQuery(".pass-thru", fbfContainerNode).each(function() {
              send_url += jQuery(this).attr('name').replace('form[', '').replace(']', '') + "=" + jQuery(this).val();
            });

            window.location.href = send_url;
          }
        }
        var method = 'rpc.form';
        // We are on a coresite
        // See if we have a submit
        if (typeof params._SUBMIT != 'undefined') {
          var _SUBMIT = params._SUBMIT;
          // remove the pg_submit from the params
          delete params._SUBMIT;
        }
        // Only post if we have a submit
        if (typeof _SUBMIT != 'undefined') {
          var pg_reqs = Drupal.settings.perlgem.pg_reqs;
          var strLen = pg_reqs.length;
          pg_reqs = pg_reqs.slice(0,strLen-1);
          if (_SUBMIT != 'contact+us' && _SUBMIT != 'contact_us') {
            jQuery.ajax({
              type: 'POST',
              url: '/' + _SUBMIT + '?' + pg_reqs,
              dataType: 'json',
              data: params,
              success: function(val) {
                if(val.success == 0 && params.UNSUB_ERROR != 'undefined') {
                  fbfContainerNode.html(params.UNSUB_ERROR);
                  if(_SUBMIT == 'email_optout.logic'){
                    fbfSubmitNode.removeClass("hidden");
                    jQuery("input[name='form[Email_Address]']").addClass('error');
                    jQuery(form_error_class).html(Drupal.settings.elc_form[form_id].validation_messages['Email_Address']['required']);
                    jQuery(form_error_class).show();
                  }
                  return false;
                }
                jQuery("input[name='form[Email_Address]']").removeClass('error');
                // crappy hack but we have to wait for emails to go out before redirecint
                if (typeof Drupal.settings.elc_form[form_id].success != 'undefined') {
                  fbfContainerNode.html(Drupal.settings.elc_form[form_id].success);
                }
                if (typeof Drupal.settings.elc_form[form_id].ajax != 'undefined') {
                  // add our mapping params back in
                  if (typeof Drupal.settings.elc_form[form_id] != 'undefined') {
                    params['mapping'] = Drupal.settings.elc_form[form_id];
                  }
                  //now make call for emails
                  jQuery.ajax({
                    type: 'POST',
                    url: '/elc_form_submit/' + Drupal.settings.elc_form[form_id].nid + '/' + Drupal.settings.elc_form[form_id].vid,
                    dataType: 'json',
                    data: params,
                    success: function(val) {
                      // crappy hack but we have to wait for emails to go out before redirecint
                      if (typeof Drupal.settings.elc_form[form_id].success_page != 'undefined') {
                        var send_url = Drupal.settings.elc_form[form_id].success_page + "?";

                        if (Drupal.settings.elc_form[form_id].use_iframe == true){
                          send_url = iframe_url;
                        }

                        jQuery(".pass-thru", fbfContainerNode).each(function() {
                          send_url += jQuery(this).attr('name').replace('form[', '').replace(']', '') + "=" + jQuery(this).val();
                        });

                        window.location.href = send_url;
                      }

                    }
                  });
                }
                else {
                  if (typeof Drupal.settings.elc_form[form_id].success_page != 'undefined') {
                    var send_url = Drupal.settings.elc_form[form_id].success_page + "?";
                    if (Drupal.settings.elc_form[form_id].use_iframe == true){
                      send_url = iframe_url;
                    }
                    jQuery(".pass-thru", fbfContainerNode).each(function() {
                      send_url += jQuery(this).attr('name').replace('form[', '').replace(']', '') + "=" + jQuery(this).val();
                    });
                    window.location.href = send_url;
                  }
                }
              }
            });
          }
          else { // contact-us email
            if (typeof Drupal.settings.elc_form[form_id].success != 'undefined') {
              fbfContainerNode.html(Drupal.settings.elc_form[form_id].success);
            }

            if (typeof Drupal.settings.elc_form[form_id].success_page != 'undefined') {
              var send_url = Drupal.settings.elc_form[form_id].success_page + "?";

              if (Drupal.settings.elc_form[form_id].use_iframe == true){
                send_url = Drupal.settings.basePath + 'elc_form_thankyou/' + Drupal.settings.elc_form[form_id].nid;
              }

              jQuery(".pass-thru", fbfContainerNode).each(function() {
                send_url = iframe_url;
              });

              window.location.href = send_url;
            }

            // add our mapping params back in
            if (typeof Drupal.settings.elc_form[form_id] != 'undefined') {
              params['mapping'] = Drupal.settings.elc_form[form_id];
            }
            //now make call for emails
            jQuery.ajax({
              type: 'POST',
              url: '/elc_form_submit/' + Drupal.settings.elc_form[form_id].nid + '/' + Drupal.settings.elc_form[form_id].vid,
              dataType: 'json',
              data: params,
              success: function(val) {
                // crappy hack but we have to wait for emails to go out before redirecint
                if (typeof Drupal.settings.elc_form[form_id].success_page != 'undefined') {
                  var send_url = Drupal.settings.elc_form[form_id].success_page + "?";

                  if (Drupal.settings.elc_form[form_id].use_iframe == true){
                    send_url = iframe_url;
                  }

                  jQuery(".pass-thru", fbfContainerNode).each(function() {
                    send_url += jQuery(this).attr('name').replace('form[', '').replace(']', '') + "=" + jQuery(this).val();
                  });

                  window.location.href = send_url;
                }

              }
            });
          }
        }
      }
    });
  });
}
};

(function ($, Drupal, site) {
  Drupal.behaviors.elc_form = {
    attach: function(context, settings) {
      site.drupalForm.initFbF();
    }
  }
})(jQuery, Drupal, site);


function form_builder_check_letters(value) {
  var letters = /^[A-Za-z\s]+$/;  
  if(value.match(letters)) {  
    return true;  
  } else { 
    return false;  
  }  
}
